import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserRole } from '../../context/RoleContext/useUserRole';
import { UserRole } from '../../models/general.models';
import { AppRoutes, DashboardRoutes } from '../routes';
import { FC } from '../../types/general.types';

export const BASE_ADMIN_ROLE_REDIRECTS = {
  [UserRole.Anonymous]: AppRoutes.Autenthication,
  [UserRole.TeamLead]: AppRoutes.MyTeams,
  [UserRole.User]: AppRoutes.NoCompany,
};

export const BASE_TEAM_LEAD_ROLE_REDIRECTS = {
  [UserRole.Anonymous]: AppRoutes.Autenthication,
  [UserRole.Admin]: AppRoutes.MyCompanies,
  [UserRole.User]: AppRoutes.NoCompany,
};

export const BASE_ANONYMOUS_ROLE_REDIRECTS = {
  [UserRole.TeamLead]: AppRoutes.MyTeams,
  [UserRole.Admin]: AppRoutes.MyCompanies,
  [UserRole.User]: AppRoutes.NoCompany,
};

export const BASE_USER_ROLE_REDIRECTS = {
  [UserRole.TeamLead]: AppRoutes.MyTeams,
  [UserRole.Admin]: AppRoutes.MyCompanies,
  [UserRole.Anonymous]: AppRoutes.Autenthication,
};

type AuthenticationRouteProps = {
  userRoles: UserRole[];
  redirectRules: {
    [K in UserRole]?: AppRoutes | DashboardRoutes;
  };
};

const RouteAuthorizer: FC<AuthenticationRouteProps> = ({
  userRoles,
  redirectRules,
  children,
}) => {
  const { userRole } = useUserRole();

  const isAuthorized = userRoles.includes(userRole);

  const redirectPage = React.useMemo(
    () => redirectRules[userRole] || AppRoutes.Autenthication,
    [redirectRules, userRole]
  );

  return isAuthorized ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPage} replace />
  );
};

export default RouteAuthorizer;
