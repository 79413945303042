import { createNotImplementedMethod } from '../utils';
import {
  CreateLumiaThreadRequest,
  DeleteLumiaThreadRequest,
  DeleteLumiaThreadResponse,
  GetLumiaThreadImageLinkRequest,
  GetLumiaThreadImageLinkResponse,
  ListLumiaThreadMessagesRequest,
  ListLumiaThreadsRequest,
  LumiaMessage,
  LumiaThread,
} from './lumia.models';

export interface ILumiaApi {
  readonly createThread: (
    req: CreateLumiaThreadRequest
  ) => Promise<LumiaThread>;

  readonly deleteThread: (
    req: DeleteLumiaThreadRequest
  ) => Promise<DeleteLumiaThreadResponse>;

  readonly listThreads: (
    req: ListLumiaThreadsRequest
  ) => Promise<LumiaThread[]>;

  readonly listThreadMessages: (
    req: ListLumiaThreadMessagesRequest
  ) => Promise<LumiaMessage[]>;

  readonly getThreadImageLink: (
    req: GetLumiaThreadImageLinkRequest
  ) => Promise<GetLumiaThreadImageLinkResponse>;
}

export class NotImplementedLumiaApi implements ILumiaApi {
  public readonly createThread = createNotImplementedMethod();
  public readonly deleteThread = createNotImplementedMethod();
  public readonly listThreads = createNotImplementedMethod();
  public readonly listThreadMessages = createNotImplementedMethod();
  public readonly getThreadImageLink = createNotImplementedMethod();
}
