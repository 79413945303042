import * as React from 'react';

type CardProps = {
  className?: string;
  children?: React.ReactNode;
};

const Card: React.ForwardRefExoticComponent<
  CardProps & React.RefAttributes<HTMLDivElement | null>
> = React.forwardRef(({ children, className }, forwardedRef) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    forwardedRef,
    () => divRef.current
  );

  return (
    <div
      ref={divRef}
      className={`px-5 py-5 rounded-3xl shadow-lg w-full bg-white ${className}`}
    >
      {children}
    </div>
  );
});

export default Card;
