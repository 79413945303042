export enum UserRole {
  User = 'user',
  Admin = 'admin',
  TeamLead = 'team-lead',
  Anonymous = 'anonymous',
}

export interface JwtTokenInfo {
  exp: number;
}
