import { VotingDay } from '../api/team/teams.models';
import { CallbackTranslation } from '../locale/localizationContext';

export function randomPassword(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const specialCharacters = '!?£$&()"';
  const charactersLength = characters.length;
  const specialCharactersLength = specialCharacters.length;
  let result = '';

  for (let i = 0; i < length - 3; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  for (let i = 0; i < 3; i++) {
    result += specialCharacters.charAt(
      Math.floor(Math.random() * specialCharactersLength)
    );
  }

  return result;
}

export const getDayTranslation = (t: CallbackTranslation, day: VotingDay) => {
  switch (day) {
    case VotingDay.Monday:
      return t('days.monday');
    case VotingDay.Tuesday:
      return t('days.tuesday');
    case VotingDay.Wednesday:
      return t('days.wednesday');
    case VotingDay.Thursday:
      return t('days.thursday');
    case VotingDay.Friday:
      return t('days.friday');
    case VotingDay.Saturday:
      return t('days.saturday');
    case VotingDay.Sunday:
      return t('days.sunday');
  }
};

export const getValueInDelimiter = (
  value: string,
  firstDelimiter: string,
  secondDelimiter: string
) => {
  const regExp = new RegExp(`${firstDelimiter}([^)]+)${secondDelimiter}`);
  const matches = regExp.exec(value);
  return matches?.[1];
};

export function average(list: number[]) {
  return (
    Math.floor((list.reduce((a, b) => a + b, 0) / list.length) * 100) / 100
  );
}

export function fixedNumber(number: number, digits: number) {
  return Math.floor(number * Math.pow(10, digits)) / Math.pow(10, digits);
}

export function sleep(
  milliseconds: number,
  timeoutRef?: NodeJS.Timeout | null
) {
  return new Promise((resolve) => {
    if (timeoutRef !== undefined) {
      timeoutRef = setTimeout(resolve, milliseconds);
    } else {
      setTimeout(resolve, milliseconds);
    }
  });
}
