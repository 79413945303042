import * as React from 'react';
import axios from 'axios';
import { Config } from '../config';
import { GetLumiaThreadImageLinkResponse } from '../api/lumia/lumia.models';
import { sleep } from '../utils/miscellaneous';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Loading from './Loading';
import { useStorageProvider } from '../context/StorageProviderContext/useStorageProvider';
import { StorageKeys } from '../services/StorageProvider/IStorageProvider';

type LumiaImageProps = {
  imageId: string;
  companyId?: string;
  threadId?: string;
};

const LumiaImage: React.FC<LumiaImageProps> = ({
  imageId,
  companyId,
  threadId,
}) => {
  const [signedUrl, setSignedUrl] = React.useState<string>();
  const storage = useStorageProvider();
  const [imageRetriveError, setImageRetriveError] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    let timeoutRef: NodeJS.Timeout | null = null;
    let unmounted = false;

    const getSignedUrl = async () => {
      let tryCount = 0;
      let signedUrl: string | null = null;

      const instance = axios.create({
        baseURL: Config.baseUrl,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await storage.getItem(
            StorageKeys.ACCESS_TOKEN_KEY
          )}`,
        },
        timeout: 10000,
      });

      while (tryCount < 3 && signedUrl === null && !unmounted) {
        try {
          const getLumiaImageRes =
            await instance.get<GetLumiaThreadImageLinkResponse>(
              `/harmonia-fh/company/${companyId}/thread/${threadId}/image/${imageId}/signedUrl`
            );

          signedUrl = getLumiaImageRes.data.item.signedUrl;
          setSignedUrl(signedUrl);
        } catch (e) {
          tryCount++;
          await sleep(1000 * tryCount, timeoutRef);
        }
      }

      if (!signedUrl) {
        setImageRetriveError(true);
      }
    };

    if (threadId && companyId && imageId) {
      getSignedUrl();
    }

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [companyId, imageId, storage, threadId]);

  if (imageRetriveError) {
    return (
      <div className="h-32 w-32 flex justify-center items-center border rounded-2xl border-grey">
        <div className="rounded-full p-1 bg-danger flex flex-row">
          <ExclamationTriangleIcon className="h-5 w-5" color="#fff" />
          <p className="text-white ml-2">Image error</p>
        </div>
      </div>
    );
  }

  if (!signedUrl) {
    return (
      <div className="h-32 w-32 flex justify-center items-center border rounded-2xl border-grey">
        <Loading />
      </div>
    );
  }

  return (
    <img src={signedUrl} className="h-72 flex object-contain rounded-2xl" />
  );
};

export default LumiaImage;
