import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNotImplementedMethod } from '../../api/utils';
import { Loading } from '../../components';
import { UserRole } from '../../models/general.models';
import { isAuthorizedSelector } from '../../redux/general/general.selectors';
import { setIsAuthorized } from '../../redux/general/general.slice';
import { StorageKeys } from '../../services/StorageProvider/IStorageProvider';
import { useApi } from '../ApiContext/useApi';
import { useLoader } from '../LoaderContext/useLoader';
import { useStorageProvider } from '../StorageProviderContext/useStorageProvider';
import { FC } from '../../types/general.types';

export type UserRoleContextProps = {
  setUserRole: (role: UserRole) => void;
  userRole: UserRole;
};

export const UserRoleContext = createContext<UserRoleContextProps>({
  userRole: UserRole.Anonymous,
  setUserRole: createNotImplementedMethod(),
});

export const UserRoleProvider: FC = ({ children }) => {
  const storageProvider = useStorageProvider();
  const dispatch = useDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const [openLoader, closeLoader] = useLoader();
  const [userRole, setUserRole] = useState<UserRole>();
  const { companyApi, teamApi, userApi } = useApi();

  useEffect(() => {
    const checkToken = async () => {
      const accessToken = await storageProvider.getItem(
        StorageKeys.ACCESS_TOKEN_KEY
      );
      dispatch(setIsAuthorized(accessToken ? true : false));
    };

    checkToken();
  }, [dispatch, storageProvider]);

  useEffect(() => {
    const checkRole = async () => {
      try {
        openLoader();
        const companies = await companyApi.getMyCompanies({ admin: '1' });
        const isAdmin = companies.length > 0;
        if (isAdmin) {
          setUserRole(UserRole.Admin);
        } else {
          const user = await userApi.getMy();
          const teams = await teamApi.getTeamsForUser({
            userId: user.id,
            admin: 1,
          });
          if (teams.items.length > 0) {
            setUserRole(UserRole.TeamLead);
          } else {
            setUserRole(UserRole.User);
          }
        }
      } catch (error) {
        setUserRole(UserRole.Anonymous);
      } finally {
        closeLoader();
      }
    };

    const checkCurrentRole = async () => {
      const accessToken = await storageProvider.getItem(
        StorageKeys.ACCESS_TOKEN_KEY
      );
      if (accessToken) {
        await checkRole();
      } else {
        setUserRole(UserRole.Anonymous);
      }
    };

    if (isAuthorized !== undefined) {
      if (isAuthorized) {
        checkRole();
      } else {
        checkCurrentRole();
      }
    }
  }, [
    closeLoader,
    companyApi,
    isAuthorized,
    openLoader,
    storageProvider,
    teamApi,
    userApi,
  ]);

  return (
    <UserRoleContext.Provider
      value={{ userRole: userRole || UserRole.Anonymous, setUserRole }}
    >
      {userRole ? children : <Loading />}
    </UserRoleContext.Provider>
  );
};
