import * as React from 'react';
import { VotingDay } from '../api/team/teams.models';
import { useParams } from 'react-router-dom';
import { useGetTeamDetails, useUpdateTeam } from './api/team.api';

export default () => {
  const { teamId } = useParams();
  const { data: teamDetails } = useGetTeamDetails({ teamId: teamId! });
  const { mutateAsync: updateTeam } = useUpdateTeam();

  const onVotingDaysUpdate = React.useCallback(
    (votingDay: VotingDay) => {
      if (!teamDetails || !teamId) return;

      const isAlreadySelected = teamDetails.votingDays.includes(votingDay);
      let daysToUpdate: VotingDay[];
      if (isAlreadySelected) {
        daysToUpdate = teamDetails.votingDays.filter((vd) => vd !== votingDay);
      } else {
        daysToUpdate = [...teamDetails.votingDays, votingDay];
      }
      updateTeam({ votingDays: daysToUpdate, teamId: teamId });
    },
    [teamDetails, teamId, updateTeam]
  );

  const onNotificationTimeUpdate = React.useCallback(
    (newNotificationTime: string) => {
      updateTeam({
        notificationTime: `${newNotificationTime}:00`,
        teamId: teamId!,
        votingDays: teamDetails?.votingDays,
      });
    },
    [teamDetails?.votingDays, teamId, updateTeam]
  );

  return { onVotingDaysUpdate, onNotificationTimeUpdate };
};
