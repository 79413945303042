import { IHttpClient } from '../../services/HttpClient/IHttpClient';

import { IAlertsApi } from './IAlertsApi';
import {
  GetListBurnoutAlertsRequest,
  GetListBurnoutAlertsResponse,
  GetListTeamLeadBurnoutAlertsRequest,
  GetListTeamLeadBurnoutAlertsResponse,
} from './alerts.models';

export class AlertsApi implements IAlertsApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getListBurnoutAlerts = async (
    req: GetListBurnoutAlertsRequest
  ) => {
    const res = await this.httpClient.get<GetListBurnoutAlertsResponse>(
      `/core/company/${req.companyId}/burnoutAlerts`,
      {
        isAuthorized: true,
        params: {
          entityFilter: req.entityFilter,
        },
      }
    );
    return res.items;
  };

  public readonly getTeamLeadListBurnoutAlerts = async ({
    companyId,
    entityFilter,
    teamId,
  }: GetListTeamLeadBurnoutAlertsRequest) => {
    const res = await this.httpClient.get<GetListTeamLeadBurnoutAlertsResponse>(
      `/core/company/${companyId}/team/${teamId}/burnoutAlerts`,
      {
        isAuthorized: true,
        params: {
          entityFilter: entityFilter,
        },
      }
    );
    return res.items;
  };
}
