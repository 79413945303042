import React from 'react';
import { RouteObject } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import RouteAuthorizer from './components/AuthenticationRoute';
import { AppRoutes } from './routes';

const ProfilePage = React.lazy(() => import('../pages/profile/profile.page'));
const EditProfilePage = React.lazy(
  () => import('../pages/editProfile/editProfile.page')
);

const profileRoutes: RouteObject[] = [
  {
    path: AppRoutes.Profile,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin, UserRole.User, UserRole.TeamLead]}
        redirectRules={{
          [UserRole.Anonymous]: AppRoutes.Autenthication,
        }}
      >
        <ProfilePage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.EditProfile,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin, UserRole.User, UserRole.TeamLead]}
        redirectRules={{
          [UserRole.Anonymous]: AppRoutes.Autenthication,
        }}
      >
        <EditProfilePage />
      </RouteAuthorizer>
    ),
  },
];

export default profileRoutes;
