import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { getValueInDelimiter } from '../../utils/miscellaneous';
import { normalizeVotingDays } from '../utils';
import { ITeamApi } from './ITeamApi';
import {
  AddMemberRequest,
  AddMemberResponse,
  UpdateTeamStatusRequest,
  CreateTeamRequest,
  CreateTeamResponse,
  GetCompanyTeamDetailsRequest,
  GetCompanyTeamDetailsResponse,
  GetCompanyTeamsRequest,
  GetCompanyTeamsResponse,
  GetTeamMembersRequest,
  GetTeamMembersResponse,
  RemoveTeamMemberRequest,
  UpdateTeamRequest,
  UpdateTeamResponse,
  SetTeamMemberAsAdminRequest,
  UpdateTeamMemberMetaRequest,
  TeamStatus,
  ResponseTeam,
  GetTeamsForUserResponse,
  GetTeamsForUserRequest,
  GetTeamDetailsRequest,
  GetTeamDetailsResponse,
} from './teams.models';

export class TeamApi implements ITeamApi {
  constructor(private httpClient: IHttpClient) {}

  public readonly getTeamDetails = async ({
    teamId,
  }: GetTeamDetailsRequest) => {
    const res = await this.httpClient.get<GetTeamDetailsResponse>(
      `/core/team/${teamId}`
    );
    return res.item;
  };

  public readonly getCompanyTeams = async ({
    companyId,
    query,
    status,
    next,
    prev,
  }: GetCompanyTeamsRequest) => {
    const statusToAdd = status ? status : undefined;
    const res = await this.httpClient.get<GetCompanyTeamsResponse>(
      `/core/company/${companyId}/team`,
      {
        params: { status: statusToAdd, query, next, previous: prev },
        isAuthorized: true,
      }
    );

    const items: ResponseTeam[] = res.items.map((rt) => {
      return {
        ...rt,
        status: getValueInDelimiter(rt.statusFilter, '#', '#')! as TeamStatus,
        voteAnonymous: Boolean(rt.voteAnonymous),
      };
    });

    return {
      ...res,
      items,
    };
  };

  public readonly createTeam = async (req: CreateTeamRequest) => {
    const normalizedVotingDays = normalizeVotingDays(req.votingDays);

    const body = {
      companyId: req.companyId,
      name: req.name,
      voteAnonymous: req.voteAnonymous ? 1 : 0,
      votingDays: normalizedVotingDays,
    };

    const res = await this.httpClient.post<CreateTeamResponse>('/core/team', {
      isAuthorized: true,
      body,
    });

    return res.item;
  };

  public readonly updateTeam = async (req: UpdateTeamRequest) => {
    let normalizedVotingDays: string | undefined = undefined;
    let voteAnonymous: 1 | 0 | undefined = undefined;

    if (req.voteAnonymous !== undefined)
      voteAnonymous = req.voteAnonymous ? 1 : 0;

    if (req.votingDays)
      normalizedVotingDays = normalizeVotingDays(req.votingDays);

    const body = {
      name: req.name,
      voteAnonymous,
      votingDays: normalizedVotingDays,
      notificationTime: req.notificationTime,
    };

    const res = await this.httpClient.put<UpdateTeamResponse>(
      `/core/team/${req.teamId}`,
      {
        isAuthorized: true,
        body,
      }
    );

    return res.item;
  };

  public readonly updateTeamStatus = async ({
    teamId,
    status,
  }: UpdateTeamStatusRequest) => {
    await this.httpClient.put<void>(
      `/core/team/${teamId}/status/${status.toLocaleLowerCase()}`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly addTeamMember = async ({
    teamId,
    memberId,
  }: AddMemberRequest) => {
    const res = await this.httpClient.put<AddMemberResponse>(
      `/core/team/${teamId}/member/${memberId}`,
      {
        isAuthorized: true,
      }
    );

    return res.item;
  };

  public readonly removeTeamMember = async ({
    teamId,
    memberId,
  }: RemoveTeamMemberRequest) => {
    await this.httpClient.delete<void>(
      `/core/team/${teamId}/member/${memberId}`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly getTeamMembers = async ({
    teamId,
    next,
    prev,
  }: GetTeamMembersRequest) => {
    const res = await this.httpClient.get<GetTeamMembersResponse>(
      `/core/team/${teamId}/member`,
      {
        isAuthorized: true,
        params: {
          next,
          previous: prev,
        },
      }
    );

    return res;
  };

  public readonly getTeamsForUser = async ({
    admin,
    next,
    prev,
  }: GetTeamsForUserRequest) => {
    const res = await this.httpClient.get<GetTeamsForUserResponse>(
      `/core/team/my/active`,
      {
        isAuthorized: true,
        params: {
          next,
          previous: prev,
          admin: admin !== undefined ? String(admin) : undefined,
        },
      }
    );

    return res;
  };

  public readonly getCompanyTeamDetails = async ({
    teamId,
  }: GetCompanyTeamDetailsRequest) => {
    const res = await this.httpClient.get<GetCompanyTeamDetailsResponse>(
      `/core/team/${teamId}`,
      {
        isAuthorized: true,
      }
    );

    return res.item;
  };

  public readonly setTeamMemberAsAdmin = async ({
    teamId,
    memberId,
  }: SetTeamMemberAsAdminRequest) => {
    await this.httpClient.put<void>(
      `/core/team/${teamId}/member/${memberId}/admin`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly setTeamMemberAsUser = async ({
    teamId,
    memberId,
  }: SetTeamMemberAsAdminRequest) => {
    await this.httpClient.delete<void>(
      `/core/team/${teamId}/member/${memberId}/admin`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly updateTeamMemberMeta = async ({
    notificationTime,
    workingDays,
    memberId,
    teamId,
    notificationEnabled,
    useDefaultVotingDays,
  }: UpdateTeamMemberMetaRequest) => {
    const normalizedVotingDays =
      workingDays && normalizeVotingDays(workingDays);
    await this.httpClient.put<void>(
      `/core/team/${teamId}/member/${memberId}/meta`,
      {
        isAuthorized: true,
        body: {
          notificationTime: notificationTime && `${notificationTime}:00`,
          votingDays: normalizedVotingDays,
          notificationEnabled,
          useDefaultVotingDays,
        },
      }
    );
  };
}
