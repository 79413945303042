import { IErrorHandler } from '../services/ErrorHandler/IErrorHandler';
import { CognitoHttpClient } from '../services/HttpClient/CognitoHttpClient';
import { UmmyHttpClient } from '../services/HttpClient/UmmyHttpClient';
import {
  IStorageProvider,
  StorageKeys,
} from '../services/StorageProvider/IStorageProvider';
import { CompanyActionApi } from './companyAction/CompanyActionApi';
import { ICompanyActionApi } from './companyAction/ICompanyActionApi';
import { AuthorizationApi } from './authorization/AuthorizationApi';
import { IAuthorizationApi } from './authorization/IAuthorizationApi';
import { CompanyApi } from './company/CompanyApi';
import { ICompanyApi } from './company/ICompanyApi';
import { FeedbackApi } from './feedback/FeedbackApi';
import { IFeedbackApi } from './feedback/IFeedbackApi';
import { GamificationApi } from './gamification/GamificationApi';
import { IGamificationApi } from './gamification/IGamificationApi';
import { ITeamApi } from './team/ITeamApi';
import { TeamApi } from './team/TeamApi';
import { IUserApi } from './user/IUserApi';
import { UserApi } from './user/UserApi';
import { AlertsApi } from './alerts/AlertsApi';
import { IAlertsApi } from './alerts/IAlertsApi';
import { ILumiaApi } from './lumia/ILumiaApi';
import { LumiaApi } from './lumia/LumiaApi';

export type CreateApiParams = {
  baseUrl: string;
  errorHandler: IErrorHandler;
  storageProvider: IStorageProvider;
};

export const createApi = async ({
  baseUrl,
  errorHandler,
  storageProvider,
}: CreateApiParams) => {
  const accessToken: string | null = await storageProvider.getItem(
    StorageKeys.ACCESS_TOKEN_KEY
  );
  const refreshToken: string | null = await storageProvider.getItem(
    StorageKeys.REFRESH_TOKEN_KEY
  );

  const ummyHttpClient = new UmmyHttpClient({
    baseUrl,
    errorHandler,
    accessToken,
    refreshToken,
  });

  const cognitoHttpClient = new CognitoHttpClient({
    errorHandler,
  });

  const authorizationApi: IAuthorizationApi = new AuthorizationApi(
    cognitoHttpClient,
    ummyHttpClient,
    storageProvider
  );

  const userApi: IUserApi = new UserApi(ummyHttpClient);
  const companyApi: ICompanyApi = new CompanyApi(ummyHttpClient);
  const companyActionApi: ICompanyActionApi = new CompanyActionApi(
    ummyHttpClient
  );
  const teamApi: ITeamApi = new TeamApi(ummyHttpClient);
  const feedbackApi: IFeedbackApi = new FeedbackApi(ummyHttpClient);
  const gamificationApi: IGamificationApi = new GamificationApi(ummyHttpClient);
  const alertApi: IAlertsApi = new AlertsApi(ummyHttpClient);
  const lumiaApi: ILumiaApi = new LumiaApi(ummyHttpClient);

  return {
    authorization: authorizationApi,
    userApi,
    companyApi,
    teamApi,
    feedbackApi,
    gamificationApi,
    companyActionApi,
    alertApi,
    lumiaApi,
  };
};
