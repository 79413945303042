export const Config = {
  env: process.env.NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  webSocketUrl: process.env.REACT_APP_WS_URL,

  // Colors
  primaryColor: '#6AC982',
  secondaryColor: '#EFC319',
  cardColor: '#F4F5F7',
  primaryTextColor: '#313131',
  secondaryTextColor: '#C8C8C8',
  dangerColor: '#D14941',
  verySadColor: '#BC4027',
  sadColor: '#F19E70',
  warningColor: '#F7CE46',
  happyColor: '#B4E3C8',
  greyColor: '#A3A3A3',
  fakeColor: '#F8F8F8',
} as const;
