import { useMemo } from 'react';
import { FeatureFlags } from '../api/company/companies.models';
import { useGetMyCompanies } from './api/company.api';

const useFeatureFlags = () => {
  const { data: companies } = useGetMyCompanies(false);

  const featureFlags: FeatureFlags = useMemo(() => {
    const f = companies?.[0]?.featureFlags;
    return {
      CommentStress: !!f && f?.CommentStress !== false,
      CompanyAction: !!f && f?.CompanyAction !== false,
      GamificationChallenges: !!f && f?.GamificationChallenges !== false,
      GamificationLeaderboard: !!f && f?.GamificationLeaderboard !== false,
      Harmonia: !!f && f?.Harmonia !== false,
      HarmoniaForHrs: !!f && f?.HarmoniaForHrs !== false,
    };
  }, [companies]);

  return [featureFlags, !!featureFlags] as [FeatureFlags, boolean];
};

export default useFeatureFlags;
