export default {
  days: {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
  },
  pageTitles: {
    authentication: 'Autenticazione',
    home: 'Home',
  },
  authentication: {
    title: 'Ciao, entra per cominciare!',
    description:
      'Unisciti a noi e inizia a tener traccia del tuo umore quotidiano, o usalo con il tuo team di lavoro!',
    email: 'Email',
    acceptTerms1: 'Dichiaro di accettare ',
    acceptTerms2: ' e ',
    acceptTerms3: ' di Qomprendo',
    termsAndConditions: 'Termini & Codizioni',
    privacyPolicy: 'Privacy Policy',
    emailError: 'La email inserita non è valida',
    passwordlessExplanation:
      'Ti invieremo un codice per verifica la tua identità, inseriscilo e sarai dentro!',
    cta: 'Accedi',
  },
  authCode: {
    title: 'Inserisci il codice',
    description: 'Inserisci il codice che ti abbiamo mandato a %{email}.',
    resendCode: 'Rimanda il codice',
    codePlaceholder: 'Codice',
    confirm: 'Conferma',
  },
  noCompany: {
    title: 'Eccoti, sei entrato!',
    description:
      'Siamo felici di vederti qui! Al momento non sei amministratore di una compagnia, ne vuoi creare una? Se invece la tua azienda utilizza Qomprendo, scarica la nostra app!',
    cta: "Registra un'azienda",
    downloadTheApp1: 'Fai parte di un azienda che utilizza Qomprendo?',
    downloadTheApp2: "Scarica l'app!",
    or: 'Oppure',
    signOut: 'Logout',
  },
  createCompany: {
    title: 'Registra la tua azienda',
    description1:
      'Registrare la tua azienda è davvero semplice! Inserisci il suo nome! Dopo averla registrata otterrai',
    description2: '30 giorni di prova gratis!',
    namePlaceholder: "Nome dell'azienda",
    companyNameError: 'Il nome della compagnia è richiesto',
    code: 'Codice segreto',
    codeRequiredError: 'Il codice segreto è richiesto',
    cta: 'Registra',
  },
  dashboard: {
    home: {
      link: 'Home',
      title: 'Home',
      description:
        'In questa sezione puoi avere una panoramica su come sta andando la tua azienda',
      subtitle: 'Andamento degli ultimi 15 giorni',
      cardEngagement: 'Engagement',
      cardAvgVoto: 'Voto medio',
      cardBestArea: 'Area migliore',
      cardWorstArea: 'Area da migliorare',
      cardMostUsed: 'Area più utilizzata',
      tabMonth: 'Recap mensile',
      tabAlerts: 'Storico delle segnalazioni',
      chipUsers: 'Utenti',
      chipTeams: 'Teams',
      chipAnonymous: 'Utenti anonimi',
      email: 'Email',
      username: 'Username',
      createdAt: 'Creato il',
      stressLevel: 'Livello di stress',
      teamName: 'Nome Team',
      teamMembers: 'Membri',
      noData: 'Nessun dato da mostrare',
    },
    manageUser: {
      title: 'Pagina di %{username}',
      userInfo: 'Informazioni membro',
      username: 'Nome utente',
      email: 'Email',
      anonymousVote: 'Voto anonimo',
      yes: 'Sì',
      no: 'No',
      details: 'Dettagli utente',
      statistics: 'Statistiche',
      actionsHistory: 'Storico azioni',
      selectDateRange: 'Seleziona intervallo',
      stressLevel: 'Rischio dimissioni: %{resignRisk}%',
      teams: "Team dell'utente",
    },
    manageUsers: {
      description:
        'In questa sezione puoi gestire gli utenti della tua azienda, aggiungerne di nuovi e gestire i loro ruoli.',
      title: 'Gestisci utenti',
      link: 'Utenti',
      searchPlaceholder: 'Almeno 3 caratteri..',
      addUser: 'Aggiungi',
      email: 'Email',
      username: 'Username',
      role: 'Ruolo',
      admin: 'amministratore',
      user: 'utente',

      deleteModal: {
        title: 'Rimuovi utente',
        description: "Come mai stai rimuovendo questo utente dall'azienda?",
        voluntaryResignation: 'Dimissioni volontarie',
        expiredContract: 'Contratto scaduto',
        layoff: 'Licenziamento',
        other: 'Altro',
        explanation:
          'Utilizziamo le risposte a questa domanda per migliorare la precisione degli avvisi di burnout e licenziamento.',
        remove: 'Rimuovi',
        cancel: 'Annulla',
      },
      promoteToUserModal: {
        title: 'Confermi la promozione?',
        description:
          'Sei sicuro di volere che questo utente abbia il ruolo di "utente" all\'interno dell\'azienda?',
        confirm: 'Conferma',
        cancel: 'Annulla',
      },
      promoteToAdminModal: {
        title: 'Confermi la promozione?',
        description:
          'Sei sicuro di volere che questo utente abbia il ruolo di "amministratore" all\'interno dell\'azienda?',
        confirm: 'Conferma',
        cancel: 'Annulla',
      },
      addUserModal: {
        title: "Aggiungi un utente all'azienda",
        description:
          "Inserisci l'email dell'utente che vuoi aggiungere all'azienda. L'utente, nel caso non sia registrato, verrà automaticamente registrato ed aggiunto all'azienda. Ricordati di comunicarglielo!",
        add: 'Aggiungi',
        emailPlaceholder: 'Email',
        emailError: 'Questa email non è valida',
        cancel: 'Annulla',
        hiringDate: 'Data di assunzione',
        hiringDateDescription: "Quando l'utente è stato assunto?",
        hiringDateRequiredError: 'La data di assunzione è obbligatoria',
        hiringDateFutureError: 'La data di assunzione non può essere futura',
      },
    },
    manageTeams: {
      link: 'Team',
      title: 'Gestisci i team',
      description:
        'In questa sezione puoi gestire i team della tua azienda, crearne di nuovi e modificare i team già esistenti.',
      teamName: 'Nome',
      yes: 'Sì',
      no: 'No',
      anonymousFeedback: 'Feedback anonimo',
      active: 'Attivo',
      archived: 'Archiviato',
      workingDays: 'Giorni',
      teamStatus: 'Stato',
      edit: 'Modifica',
      archive: 'Archivia',
      reactivate: 'Riattiva',
      archiveModal: {
        titleReactivate: 'Confermi la riattivazione?',
        titleArchive: "Confermi l'archiviazione?",
        descriptionArchive:
          "Archiviando questo team sull'azienda, i suoi membri non potranno più esprimere il loro feedback a riguardo.",
        descriptionReactivate:
          "Riattivando questo team sull'azienda, i suoi membri potranno nuovamente esprimere il loro feedback su questo team.",
        cancel: 'Annulla',
        archive: 'Archivia',
        reactivate: 'Riattiva',
      },
      addTeamModal: {
        title: 'Aggiungi team',
        description:
          'Completa tutti gli step per aggiungere un nuovo team alla tua azienda.',
        name: {
          title: 'Dai un nome',
          description:
            'Scegli un nome descrittivo in modo che sia semplice per tutti capire cosa rappresenta.',
          nameError: 'Il nome è obbligatorio e di massimo 20 caratteri',
          namePlaceholder: 'Nome team',
          continue: 'Continua',
        },
        anonymousFeedback: {
          title: 'Vuoi che in questo team il feedback sia anonimo?',
          description:
            "Le persone possono sentirsi esposte se il feedback non è anonimo, ma d'altro canto un feedback nominativo ha più valore. Ricordati che puoi cambiare quest'impostazione quando vuoi",
          anonymousFeedback:
            'Si, voglio che il feedback sia anonimo per questo team',
          next: 'Avanti',
          back: 'Indietro',
        },
        inviteMembers: {
          title: 'Invita qualcuno al team!',
          description:
            "Invita gli utenti che devono far parte di questo team inserendo le loro email, gli utenti devono far già parte dell'azienda per poter entrare.",
          emailPlaceholder: 'Email utente',
          finish: 'Finito',
          back: 'Indietro',
        },
      },
    },
    actions: {
      menuLabel: 'Azioni',
      title: 'Azioni e iniziative',
      description:
        "In questa sezione troverai le azioni e le iniziative che sono state prese e che hanno un impatto su tutta l'azienda",
    },
    manageTeam: {
      title: 'Gestisci %{teamName}',
      userTab: 'Membri',
      settingsTab: 'Impostazioni',
      chartTab: 'Statistiche',
      actionsTab: 'Storico azioni',
      description:
        'In questa sezione puoi gestire tutte le opzioni che riguardano questo team e gli utenti ad esso assegnati.',
      username: 'Username',
      promote: 'Promuovi',
      remove: 'Rimuovi',
      customNotifications: 'Notifiche personalizzate',
      email: 'Email',
      role: 'Utente',
      user: 'Utente',
      admin: 'Amministratore',
      anonymousFeedbackCard: {
        title: 'Vuoi che il feedback sia anonimo per questo team?',
        description:
          "Le persone possono sentirsi esposte se il feedback non è anonimo, ma d'altro canto un feedback nominativo ha più valore. È una tua scelta!",
      },
      notificationSection: {
        title: 'Notifiche e giorni lavorativi',
        description:
          'Personalizza quando inviare le notifiche ai componenti del team ed i loro giorni lavorativi predefiniti.',
        notificationAt: 'Notifica alle:',
      },
      addTeamMemberModal: {
        title: 'Aggiungi utente al team',
        description:
          "Stai per andare ad aggiungere un nuovo utente al team, comincia a scrivere la sua email e clicca sul suggerimento corretto, quando compare, per abilitare l'aggiunta. Ricordati che puoi aggiungere un utente solo se appartiene già alla tua azienda.",
        emailPlaceholder: 'Email utente',
        add: 'Aggiungi',
        cancel: 'Annulla',
      },
      removeMemberModal: {
        title: 'Confermi la rimozione?',
        description: 'Sei sicuro di voler rimuovere questo utente dal team?',
        remove: 'Rimuovi',
        cancel: 'Annulla',
      },
      promoteMemberModal: {
        title: 'Confermi la promozione?',
        description:
          'Confermi la promozione di questo membro ad %{role} del team?',
        admin: 'amministratore',
        user: 'utente',
        promote: 'Promuovi',
        cancel: 'Annulla',
      },
      customNotificationModal: {
        title: 'Notifiche personalizzate',
        notificationEnabled: 'Abilità notifiche per questo utente',
        customNotifications: 'Personalizza le notifiche per questo utente.',
        description:
          "Stai per andare a modificare i giorni lavorativi e l'orario a cui vengono inviate le notifiche a questo utente.",
        at: 'alle',
        cancel: 'Annulla',
        edit: 'Modifica',
      },
      updateTeamNameModal: {
        title: 'Modifica nome',
        description:
          'Stai per andare a modificare il nome di questo team. Utilizza un nome semplice ed esplicativo, in modo che tutti i membri del team possano capirlo ad una prima occhiata.',
        inputPlaceholder: 'Nuovo nome',
        save: 'Salva',
        cancel: 'Annulla',
      },
    },
    manageCompany: {
      title: 'Gestisci azienda',
      description:
        "Al momento l'unico dato della tua azienda è il nome, in futuro, in questa sezione saranno anche presenti i dati di fatturazione ed il piano scelto.",
      companyName: 'Nome azienda',
      edit: 'Modifica',
      info: 'Informazioni',
      editNameModal: {
        title: 'Modifica compagnia',
        description:
          'In questa sezione stai andando a modificare i dati della tua compagnia.',
        edit: 'Modifica',
        cancel: 'Annulla',
        companyNamePlaceholder: 'Nome azienda',
      },
    },
    analytics: {
      link: 'Analytics',
      title: 'Analytics',
      description:
        "In questa sezione puoi analizzare l'andamento dell'umore nella tua azienda, nei suoi team e per ogni persona che la compone.",
      newChart: 'Vuoi creare un nuovo grafico?',
      createNew: 'Crea nuovo',
      filteredBy: 'Filtrato per:',
      wholeCompany: "Tutta l'azienda",
      feedbackDistribution: 'Distribuzione feedback',
      veryGood: 'Molto Bene',
      good: 'Bene',
      bad: 'Male',
      period: 'Periodo:',
      veryBad: 'Molto Male',
      forget: 'Dimenticati',
      avarageValue: 'Punteggio medio',
      choosenPeriod: 'sul periodo scelto',
      noData: 'Non ci sono dati sul periodo scelto',
      averageFeedback: 'Voto medio',
      date: 'Data',
      noDataUserDescription:
        "È possibile che tu non possa vedere questi dati perché l'utente ha espresso solo feedback anonimi",
      noDataAverage1: 'Non ci sono abbastanza dati',
      noDataAverage2: 'per calcolare il punteggio medio',
      dateRange: 'Dal %{startDate} al %{endDate}',
      positive: 'Positivo',
      negative: 'Negativo',
      neutral: 'Neutrale',
      mixed: 'Contrastante',
      sentimentTitle: 'Sentimento dai commenti',
      positiveCategories: 'Aspetti positivi',
      feedbackCategories: 'Analisi delle aree',
      badCategories: 'Aspetti da migliorare',
      noSentimentComments:
        'Non ci sono abbastanza commenti per calcolare il sentimento predominante',
      feedbackSection: 'Feedback',
      areasSection: 'Aree',
      commentsSection: 'Commenti',
      stressTitle: 'Stress nei commenti',
      stressed: 'Stressati',
      notStressed: 'Non stressati',
      commentsNumber: 'Numero commenti',
      number: 'Numero',
      comments: 'Commenti',
      emotionsTitle: 'Emozioni nei commenti',
      emotions: {
        anger: 'Rabbia',
        fear: 'Paura',
        joy: 'Felicità',
        sadness: 'Tristezza',
        disgust: 'Disgusto',
      },
      feedbackDetailsModal: {
        title: 'Feedback del ',
        titleCategory: 'Feedback dal %{startDate} al %{endDate} con voto: ',
        titleMultiDate: 'Feedback dal %{startDate} al %{endDate}',
        anonymous: 'anonimo',
        email: 'Email',
        vote: 'Voto',
        comment: 'Commento',
        selectedAreas: 'Aree selezionate',
        areas: 'Aree che hanno influenzato',
        named: 'Nominativo',
        anonymousFilter: 'Anonimo',
      },
      additionalData: {
        title: 'Informazioni generali',
        sentimentFromComment: 'Sentimento dai commenti:',
        basedOn: 'basato su',
        comments: 'commenti',
        averageVote: 'Voto medio (1-4 maggiore è meglio):',
        anonymousPercentage: 'Feedback anonimi: ',
      },
      addChart: {
        title: 'Aggiungi grafico',
        description: 'Completa tutti gli step per creare un nuovo grafico.',
        chooseEntity: {
          title: 'Per cosa vuoi filtrare?',
          company: "Tutta l'azienda",
          team: 'Team',
          user: 'Utente',
          next: 'Avanti',
          cancel: 'Annulla',
        },
        chooseTeamUser: {
          whichTeam: 'Quale team?',
          chooseTeam: 'Scegli un team...',
          description:
            "Comincia a scrivere e seleziona il valore corretto dell'autocompletamento",
          whichUser: 'Quale utente?',
          userEmailPlaceholder: 'Email utente',
          next: 'Avanti',
          back: 'Indietro',
        },
        chooseDates: {
          title: 'Per quali date vuoi filtrare?',
          description:
            'Puoi scegliere al massimo un intervallo di tempo di 3 mesi.',
          back: 'Indietro',
          finish: 'Crea',
        },
      },
    },
    leaderboard: {
      link: 'Gamification',
      title: 'Gamification',
      description:
        'In questa sezione puoi vedere la classifica, di questo mese, delle prime 100 persone più attive della tua azienda.',
      positionColumn: 'Pos.',
      emailColumn: 'Email',
      usernameColumn: 'Username',
      pointsColumn: 'Punti',
      leaderboardTab: 'Classifica',
      challengeTab: 'Sfida mensile',
      gamification: {
        results: 'Risultati fino ad ora',
        current: 'Sfida corrente',
        history: 'Storico sfide',
        noChallenge: 'Non ci sono sfide in corso',
        loading: 'Caricamento...',
        noData: 'Non ci sono dati da mostrare',
        daysLeft: 'Giorni rimanenti:',
        prizes: 'Premi',
        unlockAllPrizes:
          'La tua azienda ha sbloccato tutti i premi, complimenti!',
        quantity: 'Quantità',
        nextPrice: 'Prossimo premio: %{currentPoints} / %{targetPoints}',
        noHistory: 'Non ci sono sfide passate',
        historySection: {
          allResults: 'Tutti i risultati ottenuti',
          previousChallenges: 'Le competizioni passate',
          range: 'Periodo',
          prize: 'Premio',
          quantity: 'Quantità',
        },
      },
      selectMonth: 'Seleziona mese:',
    },
    lumia: {
      link: "Chiedi all'AI",
      title: 'Lumia',
      description:
        'In questa pagina puoi fare analisi sui dati e studiare strategie per risolvere le possibili problematiche semplicemente chiedendo cosa ti interessa.',
      conversations: 'Conversazioni',
      newConversations: 'Nuova conversazione',
      connectionError:
        "C'è un problema di connesione, prova a ricaricare la pagina.",
    },
    changeCompany: 'Cambia azienda',
  },
  myCompanies: {
    title: 'Aziende di cui sei amministratore',
    description:
      'Queste sono le aziende in cui tu hai il ruolo di amministratore, sceglione una per cominciare a gestirla!',
  },
  myTeams: {
    title: 'Team di cui sei amministratore',
    description:
      'Questi sono i team in cui tu hai il ruolo di amministratore, sceglione una per cominciare a gestirlo!',
  },
  profile: {
    username: 'Nome utente',
    email: 'Email',
    changeProfileImage: 'Cambia immagine profilo',
    signOut: 'Logout',
    requestDelete: 'Richiedi cancellazione',
    accountDelete: {
      subject: 'Cancellazione account - %{userId}',
      body: `Richiedo la cancellazione del mio account Qomprendo che ha i seguenti dati:
  -  Id: %{userId}
  -  Email: %{userEmail}
  -  Nome utente: %{username}
      `,
    },
  },
  editProfile: {
    title: 'Modifica profilo',
    description:
      'In questa sezione puoi modificare il tuo profilo, al momento solamente il nome utente può essere modificato.',
    usernamePlaceholder: 'Nome utente',
    usernameError: 'Il nome utente deve contenere almeno 3 caratteri',
    save: 'Salva profilo',
  },
  shared: {
    companyActionsTable: {
      implementationDate: 'Data di attuazione',
      title: 'Titolo',
      actionLevel: "Soggetto dell'azione",
      actionType: 'Tipo',
    },
    functionLocked: {
      title: 'Questa funzionalità non è disponibile',
      description:
        "Questa funzionalità non è disponibile per la tua azienda, se vuoi utilizzarla, contatta l'assistenza.",
    },
    companyActionLevel: {
      company: 'Azienda',
      team: 'Team',
      user: 'Utente',
    },
    companyActionType: {
      oneToOne: '1 on 1',
      action: 'Azione/Iniziativa',
    },
    createCompanyActionModal: {
      title: '%{action} azione %{level}',
      add: 'Aggiungi',
      edit: 'Modifica',
      view: 'Visualizza',
      userTypeAction: 'Tipo',
      actionType: 'Azione/Iniziativa',
      oneOnOneType: '1 on 1',
      actionDescription:
        "Stai per andare ad aggiungere un'azione il cui soggetto è %{actionLevel}.",
      user: 'utente',
      team: 'team',
      company: 'azienda',
      userLevel: 'questo utente',
      teamLevel: 'questo team',
      companyLevel: "l'azienda",
      headingPlaceholder: 'Titolo',
      descriptionPlaceholder: 'Descrizione',
      implementationDatePlaceholder: 'Data di attuazione',
      save: 'Salva',
      startEdit: 'Comincia modifica',
      cancel: 'Annulla',
      formErrors: {
        titleRequired: 'Il titolo è un campo necessario',
        titleTooLong: 'Il titolo non può superare i 40 caratteri',
        descriptionRequired: 'La descrizione è un campo necessario',
        descriptionTooLong: 'La descrizione non può superare i 1500 caratteri',
        implementationDateReq: 'La data di attuazione è un campo necessario',
      },
    },
    deleteCompanyActionModal: {
      title: 'Rimuovi azione',
      description:
        'Sei sicuro di voler rimuovere questa azione? Questo intervento non è reversibile.',
      cancel: 'Annulla',
      remove: 'Rimuovi',
    },
    companyArea: {
      privatelife: 'Vita privata',
      worklifebalance: 'Equilibrio tra vita privata e lavoro',
      workload: 'Carico di lavoro',
      coworkersrelation: 'Relazione con i colleghi',
      managementrelation: 'Relazione con la dirigenza',
      taskperformed: 'Compiti svolti',
      unknown: 'Sconosciuto',
    },
    areaRisk: {
      tooltipLabel: 'Numero di voti',
    },
  },
  teamDashboard: {
    selectedTeam: 'Team selezionato',
    statistics: {
      link: 'Statistiche',
      title: 'Statistiche',
      description:
        "In questa pagina puoi avere un resoconto delle statistiche del team riguardanti l'umore e le aree d'interesse",
    },
    actionHistory: {
      link: 'Storico Azioni',
      title: 'Storico Azioni',
      description:
        'In questa pagina è presente lo storico delle azioni o eventi avvenuti sul team selezionato',
    },
    alerts: {
      link: 'Allarmi',
      title: 'Allarmi',
      description:
        'In questa pagina sono presenti gli allarmi che sono stati inviati su questo team, gli utenti di questo team e quelli anonimi',
    },
    settings: {
      link: 'Impostazioni',
      title: 'Impostazioni',
      description:
        'In questa pagina puoi gestire le impostazioni che riguardano il team, come i giorni lavorativi e le notifiche',
    },
    members: {
      link: 'Membri',
      title: 'Membri',
      description:
        'In questa pagina sono presenti i membri del team selezionato, da qui è possibile gestirli e gestire i loro attributi',
    },
  },
};
