import { createNotImplementedMethod } from '../utils';
import {
  GetListBurnoutAlertsRequest,
  Alert,
  GetListTeamLeadBurnoutAlertsRequest,
} from './alerts.models';

export interface IAlertsApi {
  readonly getListBurnoutAlerts: (
    req: GetListBurnoutAlertsRequest
  ) => Promise<Alert[]>;
  readonly getTeamLeadListBurnoutAlerts: (
    req: GetListTeamLeadBurnoutAlertsRequest
  ) => Promise<Alert[]>;
}

export class NotImplementedAlertsApi implements IAlertsApi {
  public readonly getListBurnoutAlerts = createNotImplementedMethod();
  public readonly getTeamLeadListBurnoutAlerts = createNotImplementedMethod();
}
