import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppQuery } from './utils';
import {
  GetListBurnoutAlertsRequest,
  GetListTeamLeadBurnoutAlertsRequest,
} from '../../api/alerts/alerts.models';

export const useGetListBurnoutAlerts = (
  req: GetListBurnoutAlertsRequest,
  isEnabled: boolean = true
) => {
  const { alertApi } = useApi();
  const queryRes = useAppQuery(
    [InvalidationTag.Alerts, req],
    ({ queryKey }) => {
      const reqParams = queryKey[1] as GetListBurnoutAlertsRequest;
      return alertApi.getListBurnoutAlerts(reqParams);
    },
    {
      enabled: isEnabled,
    }
  );
  return queryRes;
};

export const useGetTeamLeadListBurnoutAlerts = (
  req: Partial<GetListTeamLeadBurnoutAlertsRequest>,
  isEnabled: boolean = true
) => {
  const { alertApi } = useApi();
  const queryRes = useAppQuery(
    [InvalidationTag.Alerts, req],
    ({ queryKey }) => {
      const reqParams = queryKey[1] as GetListTeamLeadBurnoutAlertsRequest;
      return alertApi.getTeamLeadListBurnoutAlerts(reqParams);
    },
    {
      enabled: isEnabled,
    }
  );
  return queryRes;
};
